<template>

  <div class="row" ref="contactUsView">

    <div class="col-12">

      <card>
        <div slot="header">
          <h4 class="card-title">
            {{ formTitle }}
          </h4>
        </div>

        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">First Name</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'first_name') }}
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">Last Name</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'last_name') }}
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">Email</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'email') }}
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">Phone</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'phone') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="typo-line">
                  <p>
                    <span class="category">message</span>
                    {{ getValueOrDash(contactUsRequestDetails, 'message') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer text-right">

        </div>
      </card>


    </div>

  </div>
</template>
<script>

export default {

  components: {},

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,

      contactUsRequestDetails: null,

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.contactUsView
    });

    this.id = this.$route.params['id'];
    this.formTitle = "Contact Us Request Details";
    if (this.id !== undefined) {
      this.getContactUsRequest();
    } else {
      this.$notify({
        message: "Contact Us Request Not Found",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {

    getContactUsRequest() {
      this.axios.post("contact-us/get", {id: +this.id}).then((response) => {
        this.contactUsRequestDetails = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Contact Us Request Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    }
  }
}
</script>

<style>
</style>
